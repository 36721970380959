import { FiUsers, FiSettings, FiYoutube, FiCreditCard, FiUserPlus, FiAlertTriangle, FiCoffee, FiEdit } from "react-icons/fi";
import { VscHistory, VscDashboard } from "react-icons/vsc";
import { GoCalendar, GoSettings, GoClock, GoMail, GoTasklist, GoGraph } from "react-icons/go";
import { HiOutlineDocumentReport, HiOutlineDesktopComputer, HiOutlineClipboardList, HiOutlineClipboard, HiOutlineChartPie, HiOutlinePhotograph, HiOutlineUsers, HiOutlineBell, HiOutlineBriefcase, HiOutlineCode, HiOutlineSparkles, HiOutlineViewGridAdd, HiOutlineClock, HiOutlineLightningBolt } from "react-icons/hi";
import { MdEvent, MdDvr, MdPieChartOutlined, MdPhonelinkOff, MdEventNote, MdFormatListBulleted, MdNotificationsNone, MdAccessTime } from "react-icons/md";
import { BsClockHistory } from "react-icons/bs";
import { FaUserClock, FaFileAlt } from "react-icons/fa";
import { BsChatDots } from "react-icons/bs";
export var webMenu = [{
  header: "Realtime",
  menu: [{
    path: "overview",
    name: "Overview",
    icon: HiOutlineChartPie,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head"],
    tourClassName: "tour-fourth-step"
  }, {
    path: "my-teams",
    name: "My Team",
    icon: FiUsers,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head"],
    tourClassName: ""
  }]
}, {
  header: "Project & Task",
  menu: [{
    path: "task-overview",
    name: "Task Overview",
    icon: VscDashboard,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head", "member"],
    tourClassName: ""
  }, {
    path: "my-tasks",
    name: "My Tasks",
    icon: FiEdit,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head", "member"],
    tourClassName: ""
  }, {
    path: "my-projects",
    name: "My Projects",
    icon: HiOutlineBriefcase,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head"],
    tourClassName: ""
  }, {
    path: "work-history",
    name: "Work History",
    icon: MdFormatListBulleted,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head"],
    tourClassName: ""
  }, {
    path: "project-report",
    name: "Project Report",
    icon: HiOutlineClipboardList,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head"],
    tourClassName: ""
  }

  // {
  //   path: "review-manual-time",
  //   name: "Review Manual Time",
  //   icon: BsCardChecklist,
  //   pageHeader: [
  //     {
  //       userTeam: "Designer",
  //       day: "Today",
  //     },
  //   ],
  //   role: ["admin", "head"],
  //   tourClassName: "",
  // },
  ]
}, {
  header: "Proof of work",
  menu: [{
    path: "my-work",
    name: "My Work",
    icon: HiOutlineChartPie,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["member"],
    tourClassName: ""
  }, {
    path: "screenshots",
    name: "Screenshots",
    icon: HiOutlinePhotograph,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "member", "head"],
    tourClassName: ""
  }, {
    path: "timelapse",
    name: "Timelapse Videos",
    icon: FiYoutube,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "member", "head"],
    tourClassName: ""
  }, {
    path: "timesheet",
    name: "Timesheet",
    icon: MdEventNote,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "member", "head"],
    tourClassName: ""
  }, {
    path: "risk-user-history",
    name: "Risk Users",
    icon: FiAlertTriangle,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head"],
    tourClassName: ""
  }, {
    path: "timeline",
    name: "Timeline",
    icon: MdDvr,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head"],
    tourClassName: ""
  }, {
    path: "manual-time",
    name: "Manual Time",
    icon: MdPhonelinkOff,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head", "member"],
    tourClassName: ""
  }, {
    path: "reports/daily-attendance",
    name: "Reports",
    icon: HiOutlineDocumentReport,
    secondMenu: true,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head"],
    tourClassName: ""
  }, {
    path: "real-time-alert",
    name: "Real Time Alert",
    icon: MdNotificationsNone,
    secondMenu: true,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin"],
    tourClassName: ""
  }]
}, {
  header: "Leave Management",
  menu: [{
    path: "apply-leave",
    name: "Apply Leave",
    icon: MdEvent,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "member", "head"],
    tourClassName: ""
  }, {
    path: "leave-summary",
    name: "Leave Summary",
    icon: HiOutlineClipboard,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head", "member"],
    tourClassName: ""
  }, {
    path: "manage-leave",
    name: "Manage Leave",
    icon: GoCalendar,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head", "member"],
    tourClassName: ""
  }, {
    path: "manage-holiday",
    name: "Manage Holiday",
    icon: HiOutlineSparkles,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head"],
    tourClassName: ""
  }, {
    path: "view-holiday",
    name: "View Holiday",
    icon: HiOutlineSparkles,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["member"],
    tourClassName: ""
  }]
}, {
  header: "Apps Usage",
  menu: [{
    path: "review-usage",
    name: "Review Apps",
    icon: GoTasklist,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head"],
    tourClassName: ""
  }, {
    path: "apps-history",
    name: "Apps History",
    icon: VscHistory,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head"],
    tourClassName: ""
  }, {
    path: "apps-summary",
    name: "Apps Summary",
    icon: MdPieChartOutlined,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin"],
    tourClassName: ""
  }]
}, {
  header: "Configuration",
  menu: [{
    path: "settings/manage-teams",
    name: "Settings",
    icon: FiSettings,
    secondMenu: true,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head", "member"],
    tourClassName: ""
  }, {
    path: "billing",
    name: "Billing",
    icon: FiCreditCard,
    secondMenu: false,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin"],
    tourClassName: ""
  }, {
    path: "https://love.getworkfolio.com/",
    name: "Share a Testimonial",
    icon: BsChatDots,
    secondMenu: false,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "head"],
    tourClassName: ""
  }]
}];
export var desktopMenu = [{
  header: null,
  menu: [{
    path: "home",
    name: "Overview",
    icon: HiOutlineChartPie,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }]
  }, {
    path: "activity",
    name: "Activity",
    icon: MdPieChartOutlined,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }]
  }, {
    path: "timelapse",
    name: "Timelapse Videos",
    icon: FiYoutube,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }]
  }, {
    path: "screenshots",
    name: "Screenshots",
    icon: HiOutlinePhotograph,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }]
  }, {
    path: "timesheet",
    name: "Timesheet",
    icon: MdEventNote,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }]
  }, {
    path: "manual-tasks",
    name: "Manual Tasks",
    icon: MdPhonelinkOff,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }]
  }]
}];
export var webSecondMenu = {
  settings: [{
    header: null,
    menu: [{
      path: "manage-teams",
      name: "Manage Teams",
      icon: HiOutlineUsers,
      tourClassName: "tour-first-step",
      role: ["admin"]
    }]
  }, {
    header: null,
    menu: [{
      path: "manage-employees",
      name: "Manage Employees",
      icon: FiUserPlus,
      tourClassName: "tour-second-step",
      role: ["admin", "head"]
    }]
  }, {
    header: null,
    menu: [{
      path: "track-settings",
      name: "Track Settings",
      icon: GoSettings,
      tourClassName: "tour-third-step",
      role: ["admin"]
    }]
  }, {
    header: null,
    menu: [{
      path: "risk-settings",
      name: "Risk User Settings",
      icon: HiOutlineBell,
      role: ["admin"]
    }]
  }, {
    header: null,
    menu: [{
      path: "email-reports",
      name: "Email Reports",
      icon: GoMail,
      role: ["admin", "head"],
      tourClassName: ""
    }]
  }, {
    header: null,
    menu: [{
      path: "api-token",
      name: "API Token",
      icon: HiOutlineCode,
      role: ["admin"],
      tourClassName: ""
    }]
  }, {
    header: null,
    menu: [{
      path: "rebrand-settings",
      name: "Rebrand Settings",
      icon: HiOutlineLightningBolt,
      tourClassName: "",
      role: ["admin"]
    }]
  }, {
    header: null,
    menu: [{
      path: "leave-settings",
      name: "Leave Settings",
      icon: HiOutlineViewGridAdd,
      tourClassName: "",
      role: ["admin", "head", "member"]
    }]
  }
  // {
  //   header: null,
  //   menu: [
  //     {
  //       path: "project-settings",
  //       name: "Project Settings",
  //       icon: HiOutlineClipboardList,
  //       role: ["admin", "head"],
  //       tourClassName: "",
  //     },
  //   ],
  // },
  ],
  reports: [{
    header: null,
    menu: [{
      path: "daily-attendance",
      name: "Daily Attendance",
      icon: MdEventNote,
      role: ["admin", "head"]
    }]
  }, {
    header: null,
    menu: [{
      path: "monthly-attendance",
      name: "Monthly Attendance",
      icon: GoCalendar,
      role: ["admin", "head"]
    }]
  }, {
    header: null,
    menu: [{
      path: "monthly-in-out",
      name: "Monthly In Out",
      icon: GoClock,
      role: ["admin", "head"],
      tourClassName: ""
    }]
  }, {
    header: null,
    menu: [{
      path: "late-clock-in",
      name: "Late Clock-In",
      icon: BsClockHistory,
      role: ["admin", "head"],
      tourClassName: ""
    }]
  }, {
    header: null,
    menu: [{
      path: "over-time-report",
      name: "Over Time Report",
      icon: FaUserClock,
      role: ["admin", "head"],
      tourClassName: ""
    }]
  }, {
    header: null,
    menu: [{
      path: "custom-report",
      name: "Custom Report",
      icon: HiOutlineViewGridAdd,
      role: ["admin", "head"],
      tourClassName: ""
    }]
  }, {
    header: null,
    menu: [{
      path: "apps-usage",
      name: "Apps & Sites Usage",
      icon: HiOutlineDesktopComputer,
      role: ["admin", "head"],
      tourClassName: ""
    }]
  }, {
    header: null,
    menu: [{
      path: "work-activity-log",
      name: "Work Activity Log",
      icon: MdAccessTime,
      role: ["admin", "head"],
      tourClassName: ""
    }]
  },
  // {
  //   header: null,
  //   menu: [
  //     {
  //       path: "project-report",
  //       name: "Project Report",
  //       icon: AiOutlineFundProjectionScreen,
  //       role: ["admin", "head"],
  //       tourClassName: "",
  //     },
  //   ],
  // },
  {
    header: null,
    menu: [{
      path: "work-load-analysis",
      name: "Work Load Analysis",
      icon: GoGraph,
      role: ["admin", "head"],
      tourClassName: ""
    }]
  }, {
    header: null,
    menu: [{
      path: "manual-time-report",
      name: "Manual Time Report",
      icon: HiOutlineClipboardList,
      role: ["admin", "head"],
      tourClassName: ""
    }]
  }, {
    header: null,
    menu: [{
      path: "shift-time",
      name: "Shift Time Report",
      icon: HiOutlineClock,
      role: ["admin", "head"],
      tourClassName: ""
    }]
  }, {
    header: null,
    menu: [{
      path: "summary-report",
      name: "Summary Report",
      icon: HiOutlineClipboardList,
      role: ["admin"],
      tourClassName: ""
    }]
  }]
  // "manual-time": [
  //   {
  //     header: null,
  //     menu: [
  //       {
  //         path: "add-manual-time",
  //         name: "Add Manual Time",
  //         icon: BiCalendarEvent,
  //         role: ["admin", "head"],
  //       },
  //     ],
  //   },
  //   {
  //     header: null,
  //     menu: [
  //       {
  //         path: "review-manual-time",
  //         name: "Review Manual Time",
  //         icon: GoCalendar,
  //         role: ["admin", "head"],
  //       },
  //     ],
  //   },
  // ],
};
export var webUserMenu = [{
  header: "Project & Task",
  menu: [{
    path: "task-tracker",
    name: "Task Tracker",
    icon: HiOutlineChartPie,
    pageHeader: [{
      userTeam: "Designer",
      day: "Today"
    }],
    role: ["admin", "member", "head"],
    tourClassName: "tour-fourth-step"
  }]
}];