var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from "@apollo/client";
export var SHIFT_USER_LISTING_QUERY = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query getShiftUserListing($organisationId: String!, $teamId: String!, $userRole: String!) {\n    getShiftUserListing(organisationId: $organisationId, teamId: $teamId, userRole: $userRole) {\n      value\n      label\n      disabled\n    }\n  }\n"])));
export var ADD_SHIFT_MUTATION = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation addShiftTime(\n    $shiftName: String!\n    $startTime: String!\n    $endTime: String!\n    $organisationId: String!\n    $timeZone: String!\n    $shiftMembers: [String]!\n  ) {\n    addShiftTime(\n      shiftName: $shiftName\n      startTime: $startTime\n      endTime: $endTime\n      organisationId: $organisationId\n      timeZone: $timeZone\n      shiftMembers: $shiftMembers\n    )\n  }\n"])));
export var GET_SHIFT_DETAILS_QUERY = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query getShiftListing($organisationId: String!, $timeZone: String!, $limit: Int, $offset: Int) {\n    getShiftListing(organisationId: $organisationId, timeZone: $timeZone, limit: $limit, offset: $offset) {\n      shiftId\n      shiftName\n      startTime\n      endTime\n      totalMembers\n      activeMembers\n      memberIds\n      startTimeTimestamp\n      endTimeTimestamp\n      userMemberList {\n        label\n        value\n      }\n    }\n  }\n"])));
export var GET_SHIFT_MODAL_DETAILS_QUERY = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query getShiftTimeDetails(\n    $organisationId: String!\n    $timeZone: String!\n    $limit: Int\n    $offset: Int\n    $startDate: Float!\n    $endDate: Float!\n    $shiftId: String!\n    $startTime: String\n    $endTime: String\n    $startTimeFormate: [Float]\n    $endTimeFormate: [Float]\n  ) {\n    getShiftTimeDetails(\n      organisationId: $organisationId\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      startDate: $startDate\n      endDate: $endDate\n      shiftId: $shiftId\n      startTime: $startTime\n      endTime: $endTime\n      startTimeFormate: $startTimeFormate\n      endTimeFormate: $endTimeFormate\n    ) {\n      userId\n      displayName\n      profileUrl\n      workedTime\n      clockIn\n      clockOut\n      activeTime\n      totalSec\n      uniqueUrl\n\n      startStatus\n      endStatus\n      date\n    }\n  }\n"])));
export var DELETE_SHIFT_MUTATION = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation deleteShiftTime($organisationId: String!, $shiftId: String!) {\n    deleteShiftTime(organisationId: $organisationId, shiftId: $shiftId)\n  }\n"])));
export var EDIT_SHIFT_MUTATION = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  mutation editShiftTime(\n    $shiftName: String!\n    $startTime: String!\n    $endTime: String!\n    $organisationId: String!\n    $timeZone: String!\n    $shiftMembers: [String]!\n    $shiftId: String!\n  ) {\n    editShiftTime(\n      shiftName: $shiftName\n      startTime: $startTime\n      endTime: $endTime\n      organisationId: $organisationId\n      timeZone: $timeZone\n      shiftMembers: $shiftMembers\n      shiftId: $shiftId\n    )\n  }\n"])));
export var GET_SHIFT_MODAL_DETAILS_EXPORT_QUERY = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query getShiftTimeDetailsExport(\n    $organisationId: String!\n    $timeZone: String!\n    $startDate: Float!\n    $endDate: Float!\n    $shiftId: String!\n    $startTime: String\n    $endTime: String\n    $startTimeFormate: [Float]\n    $endTimeFormate: [Float]\n    $limit: Int\n    $offset: Int\n  ) {\n    getShiftTimeDetailsExport(\n      organisationId: $organisationId\n      timeZone: $timeZone\n      startDate: $startDate\n      endDate: $endDate\n      shiftId: $shiftId\n      startTime: $startTime\n      endTime: $endTime\n      startTimeFormate: $startTimeFormate\n      endTimeFormate: $endTimeFormate\n      limit: $limit\n      offset: $offset\n    ) {\n      userId\n      displayName\n      profileUrl\n      workedTime\n      clockIn\n      clockOut\n      activeTime\n      totalSec\n\n      startStatus\n      endStatus\n      date\n    }\n  }\n"])));
export var GET_SHIFT_MODAL_DETAILS_HEADER_QUERY = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  query getShiftTimeDetailsHeader(\n    $organisationId: String!\n    $timeZone: String!\n    $startDate: Float!\n    $endDate: Float!\n    $shiftId: String!\n    $startTime: String\n    $endTime: String\n    $startTimeFormate: [Float]\n    $endTimeFormate: [Float]\n  ) {\n    getShiftTimeDetailsHeader(\n      organisationId: $organisationId\n      timeZone: $timeZone\n      startDate: $startDate\n      endDate: $endDate\n      shiftId: $shiftId\n      startTime: $startTime\n      endTime: $endTime\n      startTimeFormate: $startTimeFormate\n      endTimeFormate: $endTimeFormate\n    ) {\n      workedUserCount\n      topPerformer\n    }\n  }\n"])));